<template>
  <div class="template-container fill-height" v-if="!isLoading && templates">
    <v-row no-gutters justify="space-between" align="center">
      <v-col cols="auto">
        <v-row>
          <v-col>
            <span class="section-text">Plantillas</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto">
        <ContractTemplateCreationDialog v-if="isLegalProfile()">
          <template slot="text">
            Crear plantilla
          </template>
        </ContractTemplateCreationDialog>
      </v-col>
    </v-row>
    <!-- Content -->
    <v-row>
      <v-col cols="12" md="3">
        <v-row>
          <v-col>
            <div class="filters">
              <ContractTemplateFilters :country-filter="isLegalProfile()" />
            </div>
            <div class="folders">
              <TemplateFolders />
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="topFolders != null">
        <div v-if="topFolders.folders.length === 0 && topFolders.templates.length === 0" class="no-contracts-container">
          <div class="topFolders">
            <TopFolders></TopFolders>
          </div>
          <v-row align="center" justify="center" class="fill-height">
            <v-col cols="auto">
              <v-row align="center" justify="center" class="no-content-description">
                <v-col cols="auto">
                  <div class="image-container">
                    <img
                      src="@/assets/images/empty-contracts.png"
                      alt="Addiuva"
                    >
                  </div>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="auto">
                  <p class="no-content-text text-center">
                    Aún no tienes plantillas,
                  </p>
                  <p class="text-center no-content-text">
                    Inicia el proceso de creación.
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <v-col v-else cols="12" md="12">
          <div class="topFolders">
            <TopFolders></TopFolders>
          </div>
          <div v-if="isIteratorLoading">
            <Loader type="single" />
          </div>
          <div v-else-if="templates.length > 0">
            <v-data-iterator
              :items="templates"
              :search="search"
              :loading="templatesLoader"
              no-data-text="No hay plantillas disponibles"
              :items-per-page="9"
              :footer-props="{itemsPerPageOptions:[9, 18, 27, -1]}"
            >
              <template v-slot:default="props">
                <v-row>
                  <v-col class="template-card-col" cols="12" sm="6" md="4" v-for="template in props.items" :key="template.idtemplate">
                    <ContractTemplateCard
                      :title="template.title"
                      :user="template.userCreated"
                      :id="template.idtemplate"
                      :type="template.templateType"
                      :country="template.country"
                      :created="template.created"
                    />
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </div>
        </v-col>
      </v-col>
    </v-row>
  </div>
  <div v-else class="loading-container">
    <Loader />
  </div>
</template>

<script>

/* Components */
import ContractTemplateCreationDialog from './CreationDialog'
import ContractTemplateCard from './Card'
import ContractTemplateFilters from './Filters'
import TemplateFolders from './Folders'
import Loader from '@/components/Base/Loader'
import TopFolders from './TopFolders'
import { colors } from '../../utils/colors'

import { mapActions, mapMutations, mapState } from 'vuex'

/* Mixins */
import permissionValidate from '@/mixins/permissionValidate'

/* Bus */
import Bus from '@/helpers/bus'

export default {
  name: 'ContractTemplate',
  components: {
    ContractTemplateCreationDialog,
    ContractTemplateCard,
    ContractTemplateFilters,
    TemplateFolders,
    Loader,
    TopFolders
  },

  data () {
    return {
      form: {},
      search: '',
      filter: {
        search: '',
        type: '',
        date: ''
      },
      isLoading: false,
      templatesLoader: false,
      isIteratorLoading: false
    }
  },

  mixins: [permissionValidate],

  computed: {
    ...mapState('template', ['templates', 'topFolders', 'goBack']),
    ...mapState(['countries'])
  },

  created () {
    if (localStorage.getItem('profile') === 'Finance') {
      this.setSnackbar({
        text: 'No tiene acceso a este módulo',
        timeout: 5000,
        showing: true,
        color: colors.error
      })
      this.$router.push('/contracts')
    } else {
      this.isLoading = true
      this.templatesLoader = true
      this.obtainTemplates()
    }
  },

  methods: {
    ...mapActions('template', ['getTemplates']),
    ...mapMutations({ setSnackbar: 'SET_SNACKBAR' }),

    obtainTemplates () {
      // Validación que permite volver a las carpetas seleccionadas cuando se abre un contrato
      if (this.goBack.level > 0) {
        if (this.goBack.goBackNav) {
          let params = {}
          params = {
            country: this.goBack.country,
            level: this.goBack.level,
            goBackNav: this.goBack.goBackNav
          }
          if (this.goBack.level === 1) params.folder = this.goBack.country
          if (this.goBack.level === 2 || this.goBack.level === 3) params.folder = this.goBack.folder
          if (this.goBack.level === 3) params.type_template = this.goBack.type_template
          this.getTemplates(params)
            .then(() => {
              this.isLoading = false
            })
            .catch(() => {
              this.isLoading = false
            })
            .finally(() => {
              Bus.$emit('navegation-go-back-update-template', this.goBack.goBackNav)
            })
        }
      } else {
        this.isLoading = true
        this.getTemplates()
          .then(() => {
            Bus.$emit('navegation-clear')
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/css/variables";

.template-container {
  padding: 26px 43px;

  .section-text {
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    color: black;
  }
}

</style>
