<template>
  <div v-if="!isLoading">
    <div v-if="isFolderSelected">
      <v-row>
        <v-col cols="auto">
          <span class="pointer" @click="goIndex()"> Mis carpetas </span>
        </v-col>
        <v-col cols="auto">
          <span> > </span>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row>
        <v-col cols="auto">
          <span class="pointer" @click="goIndex()"> Mis carpetas  </span>
        </v-col>
        <div style="display: flex;" v-for="(nav, key) in navegation" :key="key">
          <v-col cols="auto" v-if="topFolders.level > 1">
            <span> > </span>
          </v-col>
          <v-col cols="auto">
            <span class="pointer" @click="folderGoBack(nav)"> {{ nav.name }} </span>
          </v-col>
        </div>
      </v-row>
      <v-row v-if="topFolders.level === 1">
        <v-col class="contract-card-col" cols="12" sm="6" md="4" v-for="folder in topFolders.folders" :key="folder.id">
          <v-btn
            class="topBtns text-truncate"
            text
            outlined
            @click="getTemplatesFromLevel(folder)"
          >
            <v-icon class="pr-2" color="primary">mdi-folder</v-icon>
            <span>{{folder.name}}</span>
            <v-spacer></v-spacer>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-else-if="topFolders.level === 2">
        <v-col class="contract-card-col" cols="12" sm="6" md="4" v-for="folder in topFolders.folders" :key="folder.id">
          <v-btn
            class="topBtns text-truncate"
            text
            outlined
            @click="getTemplatesFromLevel(folder)"
          >
            <v-icon class="pr-2" color="primary">mdi-folder</v-icon>
            <span>{{folder.name}} - {{folder.country}}</span>
            <v-spacer></v-spacer>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
  <div v-else class="loading-container">
    <Loader />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Loader from '@/components/Base/Loader'

/* Bus */
import Bus from '@/helpers/bus'

export default {
  name: 'TopFolders',
  components: {
    Loader
  },
  data: () => ({
    items: [],
    foderSearch: '',
    isFolderSelected: false,
    folderSelected: {},
    clientFolderSelected: {},
    navegation: [],
    isLoading: false,
    scrollClass: 'scroll'
  }),
  watch: {
    topFolders: {
      handler (val) {
        // this.$emit('filters', val)
      },
      deep: true
    }
  },
  created () {
    // this.getTemplates()

    Bus.$on('template-navegation-update', (navegationInfo, item) => {
      this.navegation = navegationInfo
      this.getTemplatesFromLevel(item)
    })

    Bus.$on('template-navegation-clear', () => {
      this.navegation = []
      this.setLevelTopFolders(1)
      this.setGoBack({
        country: '',
        level: 0,
        folder: '',
        goBackNav: null
      })
    })

    // Bus para actualizar la barra de navegación y obtener las carpetas previamente seleccionadas
    Bus.$on('navegation-go-back-update-template', (item) => {
      this.navegation = item
      this.isFolderSelected = false
    })
  },
  computed: {
    ...mapState('template', ['topFolders', 'goBack'])
  },

  methods: {
    ...mapMutations('template', { setLevelTopFolders: 'SET_LEVEL_TOP_FOLDERS', setGoBack: 'SET_GO_BACK' }),
    ...mapActions('template', ['getTemplates']),

    goIndex () {
      Bus.$emit('filter-clear-all')
      this.isLoading = true
      this.getTemplates()
        .then(() => {
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
      this.navegation = []

      this.setGoBack({
        country: '',
        level: 0,
        folder: '',
        goBackNav: null
      })
    },

    getTemplatesFromLevel (item) {
      Bus.$emit('filter-clear-all')
      this.folderSelected = item
      let params = {}
      if (this.topFolders.level === 1) {
        item.level = 1
        this.navegation[0] = item
        params = {
          country: item.country,
          level: this.topFolders.level,
          folder: item.country,
          goBackNav: this.navegation
        }
        this.isLoading = true
        this.getTemplates(params)
          .then(() => {
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
          })
      } else if (this.topFolders.level === 2) {
        item.level = 2
        this.navegation[1] = item
        // this.clientFolderSelected = item
        params = {
          country: item.country,
          folder: item.id,
          level: this.topFolders.level,
          goBackNav: this.navegation
        }
        this.isLoading = true
        this.getTemplates(params)
          .then(() => {
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
          })
      }
    },

    folderGoBack (navItem) {
      this.setLevelTopFolders(navItem.level)
      this.navegation = this.navegation.slice(0, navItem.level)
      this.getTemplatesFromLevel(navItem)
    },

    showScroll () {
      if (this.topFolders) {
        if (this.topFolders.templates.length > 0) {
          return 'scroll'
        } else {
          return 'noScroll'
        }
      } else {
        return 'noScroll'
      }
    }
  }
}
</script>
<style lang="scss">

.scroll {
  max-height: 130px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.noScroll {
  height: auto;
  max-height: 800px;
}

.topBtns {
    padding-right: 0 !important;
    text-transform: none !important;
    width: 100%;
    display: flex;
    justify-content: left;
    background-color: white;
    border-radius: 5px;
}
.iconPadding{
  padding-right: 10px;
  margin-left: 10px;
}
.pointer {
  cursor: pointer;
}
</style>
