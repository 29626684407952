<template>
  <div>
    <v-card outlined class="template-card" :to="`/portal/templates/${id}`" exact>
      <v-row no-gutters justify="space-between">
        <v-col cols="auto">
          <p class="name">{{ title }} - {{ country }}</p>
          <p class="description">{{ description }}</p>
          <p class="templateType">Fecha de creación {{ created }}</p>
          <p class="templateType">{{ type }}</p>
          <!-- <p class="country">País: {{ country }}</p> -->
        </v-col>
        <v-col cols="auto" v-if="profile === 'Legal'">
          <v-menu>
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-icon
                  style="vertical-align: text-bottom;"
                  color="primary"
                  @click="shareDialog = true"
                  v-bind="attrs"
                  v-on:click.prevent
                  v-on="{ ...tooltip, ...menu }"
                >
                  mdi-dots-vertical
                </v-icon>
                </template>
                <span>Opciones</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item class="cursor-pointer">
                <v-list-item-title @click="showDeleteDialog=true"> Eliminar </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-card>
    <!-- delete template dialog -->
    <v-dialog
        v-model="showDeleteDialog"
        max-width="600"
        @click:outside="() => {showDeleteDialog = false}"
        @keydown.esc="() => {showDeleteDialog = false}"
      >
        <v-card class="pa-4" max-width="auto" height="auto">
          <v-card-title class="center centered-dialog__card">
            <h3> Eliminar plantilla </h3>
          </v-card-title>
          <v-card-text class="pb-0">
            Al eliminar la plantilla <b> {{ title }} </b> se borrarán los documentos asociados a esta plantilla y no se podrán recuperar.
          </v-card-text>
          <v-card-actions>
            <v-row align="center" justify="center">
              <v-col cols="auto">
                <v-btn
                  depressed
                  color="default-btn"
                  @click="showDeleteDialog = false"
                >
                  Cancelar
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  depressed
                  color="primary default-btn"
                  :loading="submitDeleteLoader"
                  @click="submitDelete()"
                >
                  Eliminar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { colors } from '@/utils/colors'

export default {
  name: 'ContractTemplateCard',

  props: {
    id: {
      required: true,
      type: Number
    },
    title: {
      required: true,
      type: String
    },
    user: {
      required: true,
      type: String
    },
    type: {
      required: true,
      type: String
    },
    country: {
      required: true,
      type: String
    },
    created: {
      required: true,
      type: String
    }
  },

  data () {
    return {
      showDeleteDialog: false,
      submitDeleteLoader: false
    }
  },

  methods: {
    ...mapActions('template', ['removeTemplate', 'getTemplates']),
    ...mapMutations({ setSnackbar: 'SET_SNACKBAR' }),

    async submitDelete () {
      this.submitDeleteLoader = true
      try {
        this.getTemplates()
        await this.removeTemplate(this.id)
        this.setSnackbar({
          text: 'Se ha eliminado la plantilla',
          timeout: 5000,
          showing: true,
          color: colors.primary
        })
        this.showDeleteDialog = false
      } catch (error) {
        console.error(`Card.submitDelete error: ${error}`)
        this.setSnackbar({
          text: 'No se pudo realizar la acción',
          timeout: 5000,
          showing: true,
          color: colors.error
        })
      } finally {
        this.submitDeleteLoader = false
      }
    }
  },

  computed: {
    description () {
      return `Creado por ${this.user}`
    },
    profile () { return this.$store.state.profile }
  }
}

</script>

<style lang="scss">
@import '@/assets/css/variables';

.template-card {
  height: auto;
  padding: 20px;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 6px !important;
  cursor: pointer;
  word-break: break-word;
  display: grid;

  .name {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: var(--v-dark-base);
    margin: 0;
    max-width: 100%;
    word-break: break-word;
  }

  .description {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: black;
    margin: 0;
  }

  .templateType {
    font-weight: normal;
    font-size: 12px;
    color: gray;
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .country {
    font-weight: normal;
    font-size: 12px;
    color: gray;
    margin-top: 0px;
  }
}

.template-card:hover {
  border: 1px solid var(--v-primary-base);
}

</style>
