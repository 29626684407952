import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{staticClass:"template-card",attrs:{"outlined":"","to":("/portal/templates/" + _vm.id),"exact":""}},[_c(VRow,{attrs:{"no-gutters":"","justify":"space-between"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(_vm.title)+" - "+_vm._s(_vm.country))]),_c('p',{staticClass:"description"},[_vm._v(_vm._s(_vm.description))]),_c('p',{staticClass:"templateType"},[_vm._v("Fecha de creación "+_vm._s(_vm.created))]),_c('p',{staticClass:"templateType"},[_vm._v(_vm._s(_vm.type))])]),(_vm.profile === 'Legal')?_c(VCol,{attrs:{"cols":"auto"}},[_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VIcon,_vm._g(_vm._b({staticStyle:{"vertical-align":"text-bottom"},attrs:{"color":"primary"},on:{"click":[function($event){_vm.shareDialog = true},function($event){$event.preventDefault();}]}},'v-icon',attrs,false),Object.assign({}, tooltip, menu)),[_vm._v(" mdi-dots-vertical ")])]}}],null,true)},[_c('span',[_vm._v("Opciones")])])]}}],null,false,2606604691)},[_c(VList,[_c(VListItem,{staticClass:"cursor-pointer"},[_c(VListItemTitle,{on:{"click":function($event){_vm.showDeleteDialog=true}}},[_vm._v(" Eliminar ")])],1)],1)],1)],1):_vm._e()],1)],1),_c(VDialog,{attrs:{"max-width":"600"},on:{"click:outside":function () {_vm.showDeleteDialog = false},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return (function () {_vm.showDeleteDialog = false})($event)}},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}},[_c(VCard,{staticClass:"pa-4",attrs:{"max-width":"auto","height":"auto"}},[_c(VCardTitle,{staticClass:"center centered-dialog__card"},[_c('h3',[_vm._v(" Eliminar plantilla ")])]),_c(VCardText,{staticClass:"pb-0"},[_vm._v(" Al eliminar la plantilla "),_c('b',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_vm._v(" se borrarán los documentos asociados a esta plantilla y no se podrán recuperar. ")]),_c(VCardActions,[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"depressed":"","color":"default-btn"},on:{"click":function($event){_vm.showDeleteDialog = false}}},[_vm._v(" Cancelar ")])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"depressed":"","color":"primary default-btn","loading":_vm.submitDeleteLoader},on:{"click":function($event){return _vm.submitDelete()}}},[_vm._v(" Eliminar ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }