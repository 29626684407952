<template>
<div>
  <v-expansion-panels
    v-model="panel"
    class="folders-container"
    flat
    multiple
  >
    <v-expansion-panel>
      <v-expansion-panel-header class="folders-header px-0">Carpetas</v-expansion-panel-header>
      <v-expansion-panel-content max-length="100px">
        <v-text-field solo flat outlined dense clear-icon="mdi-close-circle" label="Buscar ..." clearable v-model="foderSearch"></v-text-field>
        <v-treeview
          class="scroll"
          v-model="tree"
          :open="initiallyOpen"
          :items="templateLeftFolders"
          activatable
          item-key="id"
          return-object
          open-on-click
          :search="foderSearch"
          :active="treeViewActive"
          @update:active=" treeViewActive = []"
          >
            <template v-slot:prepend="{ open }">
              <v-icon color="primary">
                {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
              </v-icon>
            </template>
            <template v-slot:label="{ item }">
              <label class="folder-label" @click="getNavegationFromFolders(item)">{{ item.name }}</label>
            </template>
        </v-treeview>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
/* Bus */
import Bus from '@/helpers/bus'
import permissionValidate from '@/mixins/permissionValidate'

export default {
  name: 'ContractFolders',
  components: {},
  mixins: [permissionValidate],
  data: () => ({
    menu: false,
    panel: [0],
    initiallyOpen: [''],
    tree: [],
    foderSearch: '',
    createDialog: false,
    folderCountry: '',
    folderNameRules: [
      v => !!v || 'El nombre es requerido'
    ],
    countryRules: [
      v => !!v || 'El país es requerido'
    ],
    folderFormValid: false,
    treeViewActive: [],
    createButtonLoader: false
  }),
  watch: {
  },

  created () {
    this.getTemplateLeftFolders()
    this.folderCountry = this.countries.find((item) => {
      return item.country_code === localStorage.getItem('country')
    })
  },

  computed: {
    ...mapState(['countries', 'templateTypes', 'templateLeftFolders'])
  },

  methods: {
    ...mapActions('template', ['getTemplates']),
    ...mapActions(['getTemplateLeftFolders']),
    ...mapMutations('template', { setLevelTopFolders: 'SET_LEVEL_TOP_FOLDERS' }),

    getNavegationFromFolders (item) {
      const navegationInfo = []
      this.setLevelTopFolders(item.level)
      switch (item.level) {
        case 1:
          // navegationInfo.push(item)
          Bus.$emit('template-navegation-update', navegationInfo, item)
          break

        case 2:
          this.templateLeftFolders.forEach(country => {
            if (country.country === item.country) {
              navegationInfo.push(country)
              Bus.$emit('template-navegation-update', navegationInfo, item)
            }
          })
          break
      }
    }
  }
}
</script>
<style lang="scss">

.text-color {
  color: var(--v-dark-base);
}

.centerH4 {
  justify-content: center;
}

.folder-label {
    text-transform: none !important;
    width: 100%;
    display: flex;
    justify-content: left;
    cursor: pointer !important;
}

.scroll {
  max-height: 200px;
  overflow-y: scroll;
}

.folders-container {
  .v-expansion-panel {
    background: var(--v-background-base) !important;
  }

  .folders-header {
    font-weight: 500;
    color: black;
    padding-top: 0;
  }

  .v-expansion-panel-content__wrap {
    padding: 0;
  }

  .v-text-field .v-input__control .v-input__slot {
    height: 45px;
    min-height: 100%;
    border-radius: 6px;
  }

  .filter-label {
    color: var(--v-dark-base);
  }

  .mdi-close-circle {
    color: var(--v-dark-base);
  }

  .mdi-menu-up {
    color: var(--v-dark-base);
  }

  .mdi-menu-down {
    color: var(--v-dark-base) !important;
  }
}

@media screen and (max-width: 1380px) {
  .folders-container {
    .v-expansion-panel {
      padding: 0;
    }

    .v-text-field .v-input__control .v-input__slot {
      font-size: 14px;
    }

    .filter-label {
      color: var(--v-dark-base);
      font-size: 15px;
    }

    .mdi-close-circle {
      color: var(--v-dark-base);
    }
  }
}

.noUppercase {
    background-color: none !important;
    text-transform: none !important;
}
</style>
