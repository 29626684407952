const templateFilters = {
  start: 'start_date',
  end: 'end_date',
  country: 'country',
  typeTemplate: 'type_template',
  name: 'name'
}

export {
  templateFilters
}
